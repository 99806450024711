// from https://stackoverflow.com/a/42766146
// dynamically loads scripts from any URL
import { Injectable } from "@angular/core";
import { ScriptStore } from "../external-scripts";

declare var document: any;

@Injectable({
    providedIn: 'root',
})
export class ScriptService {

    // scripts are defined in the file `external-scripts.ts`
    private scripts: any = {};

    constructor() {
        ScriptStore.forEach((script: any) => {
            this.scripts[script.name] = {
                loaded: false,
                src: script.src
            };
        });
    }

    public load( ...scripts: string[] ) {
        var promises: any[] = [];
        scripts.forEach((script) => promises.push(this.loadScript(script)));
        return Promise.all(promises);
    }

    public loadScript( name: string, useCache = true ) {
        return new Promise((resolve, reject) => {
            // resolve if already loaded
            if ( useCache && this.scripts[name].loaded ) {
                resolve({script: name, loaded: true, status: 'Already Loaded'});
            } else {
                // load script
                let script = document.createElement('script');
                script.type = 'text/javascript';
                script.src = this.scripts[name].src;
                if (script.readyState) {  // IE
                    script.onreadystatechange = () => {
                        if (script.readyState === 'loaded' || script.readyState === 'complete') {
                            script.onreadystatechange = null;
                            this.scripts[name].loaded = true;
                            resolve({script: name, loaded: true, status: 'Loaded'});
                        }
                    };
                } else {  // Others
                    script.onload = () => {
                        this.scripts[name].loaded = true;
                        resolve({script: name, loaded: true, status: 'Loaded'});
                    };
                }
                script.onerror = (error: any) => resolve({script: name, loaded: false, status: 'Loaded'});
                document.getElementsByTagName('head')[0].appendChild(script);
            }
        });
    }

}
