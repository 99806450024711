import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-what-to-report',
  templateUrl: './what-to-report.component.html',
  styleUrls: ['./what-to-report.component.scss']
})
export class WhatToReportComponent implements OnInit {

  public modal: HTMLIonModalElement;

  constructor( ) {}

  ngOnInit() {
  }

}
